import React from 'react'
import { Modal, StyledModal } from "ui/Modal";
import Logo from "assets/SimpliCapital-logo.png";
import { useState } from 'react';
import { Spinner } from "react-bootstrap";
import { useCreateUser } from 'mutations/createUser';

function maskEmail(email) {
    const [username, domain] = email.split('@');
    const visibleUsernameCount = 3;
    const maskedUsername = username.slice(0, visibleUsernameCount) + '*'.repeat(Math.max(username.length - visibleUsernameCount, 0));
    const [domainName, domainExtension] = domain.split('.');
    const visibleDomainCount = 2;
    const maskedDomainName = domainName.slice(0, visibleDomainCount) + '*'.repeat(Math.max(domainName.length - visibleDomainCount, 0));

    return `${maskedUsername}@${maskedDomainName}.${domainExtension}`;
}

function maskPhoneNumber(phone) {
  
    let countryCode = '';
    let remainingNumber = phone;

    if (phone?.startsWith('+')) {
        const splitIndex = phone.slice(1).search(/\D/) + 1;
        countryCode = phone.slice(0, splitIndex + 1);
        remainingNumber = phone.slice(splitIndex + 1);
    }

    const maskedPart = '*'.repeat(Math.max(remainingNumber.length - 4, 0));
    const lastFourDigits = remainingNumber.slice(-4);


    return `${countryCode}${maskedPart}${lastFourDigits}`;
}


const UserOtpModal = (props) => {

    const [errors, setErrors] = useState("")
    const [loader,setLoader] = useState(false)
    const email = maskEmail(props.customerDetails.email)
    const phone = maskPhoneNumber(props.customerDetails.phoneNumber)
    const userAdd = useCreateUser();
    const handleClick = (e) => {
        
            setLoader(true)
            
            userAdd.mutateAsync({...props.customerDetails,method: e.method})
                .then((res) => {
                    const poolDetails = res.data;
                    setLoader(false)
                    setErrors(`A temporary password has been sent to your ${e.method === 'email' ? "email" : "phone"}. Please log in with it and set a new password.`);
                
                    setTimeout(() => {
                        props.close()
                    }, [6000]);
                })
                .catch((err) => {
                    setLoader(false)
    
                    if (err.response.status == 400) {
                        if (err.response.data.message) {
                            setErrors(err.response.data.message);
                        } else {
                            setErrors("Technical error");
                        }
                     
                    }
                });  
    }
    return (
        <Modal
            className="otp-modal otp-modal-usermanagement"
            show={props.open}
            onRequestClose={props.close}
            centered
        >
            <StyledModal style={{ padding: "1%", textAlign: "center", alignItems: 'center', display: 'flex', flexDirection: 'column', justifyContent: 'center'}} >
            {loader && <Spinner style={{    position: "absolute",
    color: "aliceblue"}}/>}
                <button className='close-Otp-modal' onClick={props.close}>x</button>
                <div className='otp-modal-logo'>
                    <img alt="SimpliCapital" src={Logo} ></img>
                </div>
                <div className='otp-modal-text'>
                    <span style={{ color: "#ced4da", fontSize: "13px" }}>
                        Choose how you want to verify your identity
                    </span>
                </div>

                <div className= "otp-error"style={{ marginBottom: "10px", color:"#ff717" }}>
                    {errors}
                </div>

                <div style={{width:"90%",marginBottom:"5%"}}>  
                    <div className='choose-otp-method' onClick={() => handleClick({ method: "sms" })}>
                    <div className='otp-sms-container'>
                        <span className='otp-messag-logo'>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chat-square-dots" viewBox="0 0 16 16">
                                <path d="M14 1a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1h-2.5a2 2 0 0 0-1.6.8L8 14.333 6.1 11.8a2 2 0 0 0-1.6-.8H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1zM2 0a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h2.5a1 1 0 0 1 .8.4l1.9 2.533a1 1 0 0 0 1.6 0l1.9-2.533a1 1 0 0 1 .8-.4H14a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2z" />
                                <path d="M5 6a1 1 0 1 1-2 0 1 1 0 0 1 2 0m4 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0m4 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0" />
                            </svg>
                        </span>
                        <span className='opt-message-text'>
                            <span style={{ fontWeight: "bold", letterSpacing: '1px' }}>
                                Text a code
                            </span>
                            <span>
                                {phone}
                            </span>
                        </span>
                    </div>



                </div>

                <div className='choose-otp-method' 
                    onClick={() => handleClick({ method: "email" })}
                >
                    <div className='otp-sms-container'>
                        <span className='otp-messag-logo'>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-envelope" viewBox="0 0 16 16">
                                <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1zm13 2.383-4.708 2.825L15 11.105zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741M1 11.105l4.708-2.897L1 5.383z" />
                            </svg>
                        </span>
                        <span className='opt-message-text' >
                            <span style={{ fontWeight: "bold", letterSpacing: '1px' }}>
                                Email a code
                            </span>
                            <span>
                                {email}
                            </span>
                        </span>
                    </div>



                </div>
                
                
                </div>
                
            </StyledModal>


        </Modal>
    )
}

export default UserOtpModal