import React from "react";
import { Button } from "ui/Buttons";
import { Modal } from "ui/Modal";
import Tags from "ui/Tags";
import { StyledModal } from "../supplier-payment";
import Checkbox from "ui/Checkbox";
import ToggleButtonComponent from "ui/ToggleButton";
import deleteicon from "../../../assets/Icon material-delete.svg";
import addicon from "../../../assets/add-icon.svg";
import { useRef } from "react";

import axios from "utils/APIv2";
import { useEffect } from "react";
import SelectWithVirtualized from "ui/SelectWithVirtualized";
import { useState } from "react";
import { ManagedefaultAssignedUsers, ManagedefaultUsers, ManagedefaultUsersAdd } from "mutations/adddiscount";
import ModalDialog from "react-bootstrap/ModalDialog";
import Draggable from "react-draggable";


const DraggableModalDialog = props => {
  return (
    <Draggable handle='.draggable-modal' bounds={{ top: - window.innerHeight + 400, left: - window.innerHeight , right: window.innerWidth - 600, bottom: window.innerHeight - 400 }}>
      <ModalDialog {...props} />
    </Draggable>
  );
};

export default function ManageDefault({ show, onHide }) {
  const [disputUsers, setdisputUsers] = useState([]);
  const [assigntoUsers, setassigntoUsers] = useState([]);

  const assignedusers = ManagedefaultAssignedUsers();
  const allusers = ManagedefaultUsers();
  const adduser = ManagedefaultUsersAdd();
  useEffect(() => {
    if (show === true) {
      getUser();
      getassignuser();
    }
  }, [show]);

  const getassignuser = async () => {

    let userres = await assignedusers.mutateAsync({});
    if (userres.data) {
      setdisputUsers(userres.data.data)
    }
  };

  const getUser = async () => {

    let response = await allusers.mutateAsync({});
    if (response.data) {
      setassigntoUsers(response.data.data)
    }
  };

  const onChange = async (e) => {
    let body = {
      userdata: {
        user_name: e.label,
        user_id: e._id,
      },
      _id: disputUsers?._id ? disputUsers._id : undefined,
    };
    let response = await adduser.mutateAsync({body});
    let b = await getassignuser();
  };

  const userDelete = async (e) => {
    let body = {
      userdata: {
        user_name: e.user_name,
        user_id: e.user_id,
      },

      _id: disputUsers?._id ? disputUsers._id : undefined,
      deleteflag: true,
    };
    let response = await adduser.mutateAsync({body});
    let assignuserdata = await getassignuser();
  };


  return (
    <Modal
      show={show}
      onHide={onHide}
      centered
      enforceFocus={false}
      size="100%"
      dialogAs={DraggableModalDialog}
    >
     <StyledModal>
     <div className={'draggable-modal'}>
        <Modal.Header closeButton>
          <h1>Manage defaults </h1>
        </Modal.Header>
     </div>

        <Modal.Body>
          <div className="right_input_inner">
            <SelectWithVirtualized
              options={
                assigntoUsers.length > 0 ? assigntoUsers : []
              }
              onChange={(e) => onChange(e)}
            />
          </div>

          <div className="cheap_wrp_new new_cheap_flex">
            {disputUsers?.users?.map((v) => (
              <>
                <div
                  style={{
                    background: "linear-gradient(270deg, rgb(51, 194, 223) 0%, rgb(0, 129, 197) 100%) transparent",
                    borderRadius: "3px",
                    width: "fit-content",
                    margin: "8px 8px 8px 0px",
                    padding: "3px 8px",
                    fontSize:"14px",
                    color: "white"
                  }}
                  onClick={() => userDelete(v)}
                  className="chipcls"
                >
                  {v.user_name}
                  <button
                    style={{
                      background: "none",
                      border: "none",
                      color: "#fff",
                      marginLeft: "5px",
                    }}
                    class="close"
                    type="button"
                  >
                    x
                  </button>
                </div>
              </>
            ))}
          </div>
        </Modal.Body>
      </StyledModal>
    </Modal>
  );
}
