import axios from "axios"

export const EventLogs = async (event) => {
    try {
        const sessionId = sessionStorage.getItem("logId")
        let ipInfo = sessionStorage.getItem("ipInfo")
        const platForm = navigator.userAgentData.platform
        const browser = navigator.userAgentData.brands[0].brand
        const { customerEmail, eventId, tenant, eventType,name,events } = event


        ipInfo = JSON.parse(ipInfo)

        let addEventDataSet = {
            tenant: tenant,
            user: customerEmail ? customerEmail:"guest",
            eventId,
            metaData: { ipInfo,...events },
            source: eventType,
            browser,
            ip: ipInfo?.ip,
            device: platForm,
            sessionId,
            name
        }
        const url = process.env.REACT_APP_LAMBDA_EVENT_URL;

        await axios.post(url, addEventDataSet)

    } catch (err) {
      console.log(err)
    }
}



export  const GetIpInfo = ()=>{
    const responseLPromise = fetch(
        process.env.REACT_APP_IP_GEO_ENDPOINT +
          "?apiKey=" +
          process.env.REACT_APP_IP_GEO_KEY
      );
      
      
      Promise.all([responseLPromise])
        .then(async ([responseL]) => {
          const dataL = await responseL.json();
          sessionStorage.setItem('ipInfo',JSON.stringify(dataL))
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
}