import React, { useEffect, useState, useContext } from "react";
import { Row, Col } from "react-bootstrap";
import styled from "styled-components";
import {
  Form,
  InputwithLabel,
  Formik,
  SimpleSelect,
  DatePickerField,
  TimePickerField,
  Radio,
} from "ui/forms";
import { EditIcon, InfoIcon } from "assets/icons";
import * as Yup from "yup";
import PrivateLayout from "layouts/organization-ui/PrivateLayout";
import Page from "ui/Page";
import Card from "ui/Card";
import { BasicButton } from "ui/Buttons";
import Accordion from "react-bootstrap/Accordion";
import Toggleicon from "assets/arrow-down-new.svg";
import { useHistory } from "react-router-dom";
import StepperComponent from "./Stepper";
import { useSectors } from "queries/sectors";
import { useDepartments } from "queries/departments";
import { useGeneralSettings } from "queries/generalSettings";
import { useGetAUserAllBankingDetails } from "queries/bankSettings";
import { useAddGeneralSettings } from "mutations/addGeneralSettings";
import {
  useAddBankSettings,
  useRemoveBankAccount,
  useBankForForecasting,
  useBankForReauthorisation,
} from "mutations/addBankSettings";
import { Field, FieldArray, ErrorMessage } from "formik";
import ErrorHandlingComponent from "ui/ErrorHandingComponent";
import Loader from "ui/Loader";
import "react-json-pretty/themes/monikai.css";
import { updateDepartments } from "services/departments.service";
import { UIContext } from "context/UIContext";
import { Route_URLs } from "routesURLs";
import Tooltip from "ui/Tooltip";
import { Authorise } from "helpers/Authorization";
import { forFutureRelease } from "utils/Configs";
import moment from "moment-timezone";
import ToggleButtonComponent from "ui/ToggleButton";
import { PlaidLink, usePlaidLink } from "react-plaid-link";
import {
  getLinkToken,
  exchangePublicTokenForAccessToken,
} from "services/bankSettings.services";
import { useQueryClient } from "react-query";
import NumberFormat from "react-number-format";
import { Label } from "filters/Style";
import SCLogo from "assets/logo.png";
import { LockIcon } from "assets/icons";
import { Modal as BSModal } from "react-bootstrap";
import CrossIcon from "assets/icon-cross.svg";
import { DEFAULT_MIN_VERSION } from "tls";
import {
  GreenClockIconBank,
  AmberClockIconBank,
  RedClockIconBank,
} from "assets/icons.js";
import ModalDialog from "react-bootstrap/ModalDialog";
import Draggable from "react-draggable";

const StyledModal = styled.div`
  .modal-text {
    text-align: left;
    font-family: Montserrat Alternates;
    font-size: 11px;
    line-height: 20px;
    letter-spacing: 0px;
    color: #909090;
    opacity: 1;
    margin-bottom: 0px;
  }
  .name {
    height: 29px;
    background-color: rgba(255, 255, 255, 0.18);
    border: 1px solid #707070;
  }
  .data {
    height: 29px;
    border-bottom: 1px solid #707070;
  }
  .modal-header {
    padding: 1rem 1rem;
    border-bottom: 1px solid #707070 !important;
    margin: 10px;
    h1 {
      font-family: Montserrat Alternates-Bold;
      text-align: left;
      font-size: 18px;
      line-height: 22px;
      color: #ffffff;
    }
  }
`;
const Modal = styled(BSModal)`
  /* z-index: 77788; */
  .modal-dialog {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .modal-content {
    background-color: #3a3b48;
    opacity: 1;
    width: fit-content;
    height: fit-content;
  }
  .modal-header {
    border-bottom: none;
    padding-bottom: 0;
    .btn-close {
      font-size: 20px;
      color: white !important;
      background-image: url(${CrossIcon});
    }
    h3 {
      font-family: Montserrat Alternates-Bold;
      font-size: 18px;
      line-height: 22px;
      letter-spacing: 0px;
      color: #ffffff;
      margin-top: 5px;
    }
    .exportdiv {
      width: 557px;
      height: 340px;
      background: #3a3b48 0% 0% no-repeat padding-box;
      box-shadow: 0px 3px 26px #00000017;
      border: 1px solid #707070;
      border-radius: 20px;
      margin: 0px 15px;
      text-align: center;
      padding: 5px;
      // cursor: pointer;
      svg {
        path {
          fill: #33c2df;
        }
      }
    }
  }
  .modal-body {
    .upper-section {
      border-top: 1px solid #d0d0d0;
      border-bottom: 1px solid #d0d0d0;
      p {
        font-family: Montserrat Alternates-semiBold;
        font-size: 12px;
        line-height: 15px;
        letter-spacing: 0px;
        color: #909090;
        margin-bottom: 5px;
      }
      .total-value {
        text-align: left;
        font-size: 22px;
        line-height: 27px;
        font-family: Montserrat Alternates-Bold;
        letter-spacing: 0px;
        color: #ffffff;
      }
      label {
        display: block;
        text-align: left;
        font-size: 13px;
        line-height: 14px;
        font-family: Montserrat Alternates-Medium;
        letter-spacing: 0px;
        color: #33c2df;
        opacity: 1;
      }
      span {
        left: 200px;
      }
    }
    .graph-section {
      .graph-filters {
        text-align: center;
        font-family: Montserrat Alternates-semiBold;
        font-size: 11px;
        line-height: 14px;
        letter-spacing: 0px;
        color: #909090;
        margin-top: 15px;
      }
    }
  }
`;

const Innersection = styled.div`
  width: 60%;
`;
const StyledLabel = styled.span`
  font-family: Montserrat Alternates;
  text-align: left;
  font-size: 11px;
  line-height: 20px;
  letter-spacing: 0px;
  color: #909090;
  opacity: 1;
  margin-bottom: 8px;
  display: inline-block;
`;
const StyledCard = styled.div``;
const OuterDiv = styled.div`
  padding: 30px;
  .custom-error {
    position: absolute;
    color: red;
    font-size: 12px;
    margin-top: 5px;
  }
  .company-name {
    font-family: Montserrat Alternates-Bold;
    font-size: 18px;
    line-height: 22px;
    color: #909090;
  }
  .company-edit {
    cursor: pointer;
    margin-bottom: 5px;
    path {
      fill: #33c2df;
    }
  }
`;
const Logo = styled.div`
  padding: 10px;
  height: 148px;
  background: #2b2b34 0% 0% no-repeat padding-box;
  border-radius: 6px;
  .logo-heading {
    font-size: 11px;
    line-height: 14px;
    font-family: Montserrat Alternates-Bold;
    letter-spacing: 0px;
    color: #909090;
  }
  .logo-edit {
    float: right;
    font-size: 11px;
    line-height: 14px;
    font-family: Montserrat Alternates-Bold;
    letter-spacing: 0px;
    color: #909090;
    svg {
      margin-right: 5px;
      margin-bottom: 3px;
      path {
        fill: #33c2df;
      }
    }
  }
  img {
    margin: 10%;
    width: 80%;
    height: 50%;
    border-radius: 5%;
  }
`;
const NavigationButtons = styled.div`
  .back-button {
    float: left;
    background: none;
    border: 2px solid #909090;
  }
  .next-button {
    float: right;
  }
  padding: 20px 38px;
`;
const StyledAccordion = styled(Accordion)`
  margin-top: 20px;
  .button-border {
    border: 1px solid #707070;
  }
  .accordion-item {
    background-color: #3a3b48;
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 1px solid #707070;
    .accordion-button:not(.collapsed) {
      box-shadow: none;
    }
  }
  .accordion-header {
    button {
      text-align: left;
      font-family: Montserrat Alternates-Bold;
      font-size: 18px;
      line-height: 22px;
      letter-spacing: 0px;
      color: #909090;
      background-color: #3a3b48;
      padding-left: 0;
      padding-right: 0;
    }
    .accordion-button::after {
      background-image: url(${Toggleicon});
    }
  }
  .accordion-body {
    padding: 1rem 0rem;
  }
`;
const BusinessSection = styled.div`
  font-size: 11px;
  line-height: 25px;
  color: #b5b5b5;
  border-top: 1px solid #707070;
  padding: 8px;
  input {
    height: 22px !important;
    width: 55px !important;
    border-radius: 0px !important;
    margin-right: 10px;
  }
`;
const UpdateForecast = styled.div`
  .form-field {
    display: inline-block;
    width: 47%;
    margin-right: 5px;
  }
  input {
    font-size: 11px !important;
  }
`;
const CashButton = styled(BasicButton)`
  background: ${(props) =>
    props.active
      ? "transparent linear-gradient(270deg, #33c2df 0%, #0081c5 100%)"
      : "#232631"};
`;
const OrderButton = styled(BasicButton)`
  background: ${(props) =>
    props.active
      ? "transparent linear-gradient(270deg, #33c2df 0%, #0081c5 100%)"
      : "#232631"};
`;
const CollectionButton = styled(BasicButton)`
  background: ${(props) =>
    props.active
      ? "transparent linear-gradient(270deg, #33c2df 0%, #0081c5 100%)"
      : "#232631"};
`;
const ReceivablesButton = styled(BasicButton)`
  background: ${(props) =>
    props.active
      ? "transparent linear-gradient(270deg, #33c2df 0%, #0081c5 100%)"
      : "#232631"};
`;
const PayablesButton = styled(BasicButton)`
  background: ${(props) =>
    props.active
      ? "transparent linear-gradient(270deg, #33c2df 0%, #0081c5 100%)"
      : "#232631"};
`;
const BackStyledButton = styled.button`
  background: transparent linear-gradient(270deg, #33c2df 0%, #0081c5 100%);
  box-shadow: 0px 3px 26px #00000017;
  border: 1px solid #707070;
  border-radius: 20px;
  text-align: left;
  font-family: Montserrat Alternates-semiBold;
  font-size: 13px;
  line-height: 15px;
  letter-spacing: 0px;
  color: #ffffff;
  padding: 12px;
`;
const plaidLinkStyles = {
  color: "#FFFFFF",
  width: "fit-content",
  paddingLeft: "1.4rem",
  paddingRight: "1.4rem",
  height: "40px",
  textAlign: "center",
  fontFamily: "Montserrat Alternates-Bold",
  fontSize: "14px",
  lineHeight: "18px",
  letterSpacing: "0px",
  borderRadius: "5px",
  outline: "none",
  border: "none",
  boxShadow: "none",
  background: "linear-gradient(270deg, #33c2df 0%, #0081c5 100%)",
};
const CheckboxWrapper = styled.div`
  display: flex;
  align-items: baseline;
  input {
    background: transparent 0% 0% no-repeat padding-box !important;
    color: #808186 !important;
  }
  input[type="checkbox"] {
    margin-bottom: -3px;
    margin-right: 5px;
    background: transparent 0% 0% no-repeat padding-box;
    &:after {
      color: transparent;
    }
    &:checked {
      &:after {
        color: #33c2df;
      }
    }
  }
`;

const DraggableModalDialog = props => {
  return (
    <Draggable handle='.draggable-modal' bounds={{ top: - window.innerHeight + 300, left: - window.innerHeight , right: window.innerWidth - 400, bottom: window.innerHeight - 300 }}>
      <ModalDialog {...props} />
    </Draggable>
  );
};

const StyledList = styled.ul``;

const ThresholdSection = styled.div``;

function GeneralSettings(props) {
  const { onBoarding } = useContext(UIContext);
  const [companyEdit, setCompanyEdit] = useState(false);
  const [thresholds, setThresholds] = useState("cash");
  const [errorMessage, setErrorMessage] = useState("");
  const [isEdited, setEdited] = useState(false);
  const [showPopover, setShowPopover] = useState(false);
  const [showBankConnectButton, setShowBankConnectButton] = useState(false);
  const [publicLinkToken, setPublicLinkToken] = useState(null);
  const queryClient = useQueryClient();
  const [letsGo, setLetsGo] = useState(false);
  const [userConsent, setUserConsent] = useState(false);
  const [industrychange, setIndustrychange] = useState(false);

  const createLinkToken = async () => {
    let tokenn = await getLinkToken();
    setPublicLinkToken(tokenn.data.link_token);
    setShowBankConnectButton(true);
  };
  const getAccessToken = async (publicToken) => {
    try {
      setShowPopover(!showPopover);
      setLetsGo(!letsGo);
      setUserConsent(!userConsent);
      let accessToken = await exchangePublicTokenForAccessToken(publicToken);
      let aToken = accessToken.data.access_token;
      if (aToken !== "") {
        await AddBankSettings.mutateAsync({
          publicToken: publicLinkToken,
          accessToken: aToken,
        })
          .then(async (res) => {})
          .catch(function (error) {
            console.log(error.response);
            // if (error.response) {
            //   setErrorMessage(error.response.data.message);
            // }
          });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const AddBankSettings = useAddBankSettings();
  const RemoveBankAccount = useRemoveBankAccount();
  const BankForForecasting = useBankForForecasting();
  const BankForReauthorisation = useBankForReauthorisation();

  const removeAccount = async (uuid) => {
    try {
      let res = await RemoveBankAccount.mutateAsync({ uuid });
      if (res) {
        queryClient.invalidateQueries("bankSettings");
      }
    } catch (e) {
      console.log(e);
    }
  };

  const reauthoriseAccount = async (uuid) => {
    try {
      let res = await BankForReauthorisation.mutateAsync({ uuid });
      if (res) {
        queryClient.invalidateQueries("bankSettings");
      }
    } catch (e) {
      console.log(e);
    }
  };

  const updateBankForForecasting = async (toUse, uuid) => {
    try {
      let res = await BankForForecasting.mutateAsync({ toUse, uuid });
      if (res) {
        queryClient.invalidateQueries("bankSettings");
      }
    } catch (e) {
      console.log(e);
    }
  };

  let history = useHistory();

  const { data: sectors, isLoading: isLoadingSectors } = useSectors();

  const { data: departments, isLoading: isLoadingDepartments } = useDepartments(
    { hierarchy: true }
  );

  const { data: generalSettingsData, isLoading } = useGeneralSettings();

  const { data: bankSettingsData, isLoadingBankDetails } =
    useGetAUserAllBankingDetails();

  const [bankAccountsData, setBankAccountsData] = useState([]);

  const [consentStartDate, setConsentStartDate] = useState();
  const [consentEndDate, setConsentEndDate] = useState();

  useEffect(async () => {
    if (bankSettingsData && bankSettingsData?.length > 0) {
      setBankAccountsData([
        ...bankSettingsData.map((e) => {
          return {
            id: e._id,
            bank: e.bankDetails.bank_name,
            useInForecast: e.useInForecast,
            totalAmountInBank: e.bankDetails.accounts.reduce((acc, base) => {
              acc = acc + base.balances.available;
              return acc;
            }, 0),
            daysLeftToReAuthorise: e.daysLeftToReAuthorise,
          };
        }),
      ]);
      setShowBankConnectButton(true);
    }
    createLinkToken();
  }, [bankSettingsData]);

  const [currentSector, setCurrentSector] = useState();

  const AddGeneralSettings = useAddGeneralSettings();

  const sectorOptions = sectors?.map(({ name }) => ({
    label: name,
    value: name,
  }));

  useEffect(() => {
    setCurrentSector(generalSettingsData?.sector);
    if (generalSettingsData?.startDate) {
      setEdited(true);
    }
  }, [generalSettingsData?.sector]);

  const subSectors = sectors
    ?.filter((sec) => sec.name === currentSector)
    .map((sub) =>
      sub.industries.map(({ name }) => ({ label: name, value: name }))
    );

  const handleSector = (value) => {
    setCurrentSector(value);
  };

  const HandleThresholds = (value) => {
    setThresholds(value);
  };
  const FormattedUpdateFormData = (values) => {
    let formData = new FormData();
    let payload = {
      homeCurrency: values.homeCurrency,
      showSalesRep: values.showSalesRep,
      showJobType: values.showJobType,
      showCustomerMemo: values.showCustomerMemo,
      company: values.companyName.trim(),
      sector: values.sector,
      industry: values.industry,
      capitalCost: values.costOfCapital,
      revenueGroup: values.revenueGroup,
      billPayment: values.billPayments,
      timezone: values.timeZone,
      autoPaymentsTime: values.autoPaymentsTime,
      updateForecastModels: values.updateForecastModels,
      cashThresholds: values.cashThresholds,
      ordersThresholds: values.ordersThresholds,
      collectionsThresholds: values.collectionsThresholds,
      receivablesThresholds: values.receivablesThresholds,
      payablesThresholds: values.payablesThresholds,
      startDate: moment(values.applicationStartDate).format("YYYY-MM-DD"),
      manageDefaults: {
        beforeDueDate: {
          isBeforeDueDate: false,
          days: 0,
        },
        onDueDate: false,
        afterDueDate: {
          isAfterDueDate: false,
          days: 0,
          perodicDaily: false,
          periodicWeekly: false,
          selectFixedTime: "",
          selectFixedDayTime: "",
        },
      },
    };

    formData.append("data", JSON.stringify(payload));
    formData.append("file", values.profileImage);
    return formData;
  };
  const handleAddGeneralSettings = (values) => {
    console.log(values);
    const newFormData = FormattedUpdateFormData(values);
    AddGeneralSettings.mutateAsync(newFormData)
      .then(() => {
        setErrorMessage(null);
        history.push(Route_URLs.setup_digital_payments);
      })
      .catch(function (error) {
        console.log(error);
        if (error.response) {
          setErrorMessage(error.response.data.message);
        }
      });
  };
  const timezonesArray = moment.tz.zonesForCountry("US")?.map((zone) => ({
    label: zone,
  }));

  useEffect(() => {
    setIndustrychange(true);
    setTimeout(() => {
      setIndustrychange(false);
    }, [100]);
  }, [generalSettingsData]);

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <PrivateLayout>
          <Page>
            <Page.Header>
              <Row className="m-0">
                <Col className="p-0">
                  {/* <StatusBarComponent /> */}

                  <h1 className="page-title">General Settings</h1>
                  <StepperComponent currentPage={onBoarding ? 1 : 8} />
                </Col>
              </Row>
            </Page.Header>
            <Page.Body>
              {showPopover && (
                <Modal
                  show={showPopover}
                  onHide={() => {
                    setShowPopover(false);
                    setLetsGo(false);
                    setUserConsent(false);
                  }}
                  centered
                  style={{}}
                  dialogAs={DraggableModalDialog}
                >
                  <div className={'draggable-modal'}>
                  <StyledModal
                    style={{
                      width: "650px",
                      marginTop: "10px",
                      paddingLeft: "10px",
                      paddingRight: "20px",
                    }}
                  >
                    <div className="bank-data-consent-modal">
                      <Modal.Body style={{ padding: "25px 15px" }}>
                        {!letsGo && (
                          <>
                            <div className="modal-header-bank-connections">
                              <img alt="SimpliCapital" src={SCLogo}></img>
                              <span className="modal-header-bank-connections-text">
                                3 quick steps to connect to your account
                              </span>
                            </div>
                            <Card style={{ border: "1px solid #6d6d6d" }}>
                              <Card.Body>
                                <p className="card-heading">
                                  We need your consent to keep bringing in your
                                  bank data.
                                </p>
                                <p className="card-grey-text">
                                  - We'll ask for your consent to share your
                                  bank transactions.
                                </p>
                                <p className="card-grey-text">
                                  - You'll sign in to your bank account to
                                  authorize the connection.
                                </p>
                              </Card.Body>
                            </Card>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "flex-end",
                              }}
                            >
                              <BasicButton
                                onClick={() => setShowPopover(false)}
                                className="card-button-grey"
                                style={{ background: "#3a3b48" }}
                              >
                                Back
                              </BasicButton>
                              <BasicButton
                                onClick={() => setLetsGo(!letsGo)}
                                className="card-button-blue"
                              >
                                Let's Go
                              </BasicButton>
                            </div>
                            <hr className="horizontal-divider-color" />
                            <Label
                              style={{
                                justifyContent: "center",
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <LockIcon />
                              <span style={{ marginLeft: "0.4rem" }}>
                                At SimpliCapital, privacy and security of your
                                information are top priorities.
                              </span>
                            </Label>
                          </>
                        )}
                        {letsGo && !userConsent && (
                          <>
                            <div className="modal-header-bank-connections">
                              <img alt="SimpliCapital" src={SCLogo}></img>
                              <span className="modal-header-bank-connections-text">
                                Consent to share your data
                              </span>
                            </div>
                            <Card style={{ border: "1px solid #6d6d6d" }}>
                              <Card.Body>
                                <p className="card-heading">
                                  Important things to know
                                </p>
                                <p className="card-grey-text">
                                  You won't need to give consent through your
                                  bank every 90 days for SimpliCapital to access
                                  your account data, instead you'll need to
                                  provide this consent direct to SimpliCapital
                                  every 90 days.
                                </p>
                                <StyledList>
                                  <li className="card-white-text">
                                    - Why and when we collect this data
                                  </li>
                                  <li style={{ marginLeft: "4px" }}>
                                    <p className="card-grey-text">
                                      Collecting this data allows SimpliCapital
                                      to generate your financial reports and
                                      other consolidated information in your
                                      SimpliCapital products. This collection
                                      will occur on an ongoing basis only while
                                      we have your active consent.
                                    </p>
                                  </li>
                                  <li className="card-white-text">
                                    - You'll need to reconfirm your consent
                                    every 90 days
                                  </li>
                                  <li style={{ marginLeft: "4px" }}>
                                    <p className="card-grey-text">
                                      Consent starts:{" "}
                                      <span
                                        style={{
                                          fontWeight: "600",
                                          fontSize: "14px",
                                        }}
                                      >
                                        {consentStartDate}
                                      </span>
                                    </p>
                                    <p className="card-grey-text">
                                      Reconfirm by:{" "}
                                      <span
                                        style={{
                                          fontWeight: "600",
                                          fontSize: "14px",
                                        }}
                                      >
                                        {consentEndDate}
                                      </span>
                                    </p>
                                  </li>
                                  <li className="card-white-text">
                                    - You can withdraw your consent at any time
                                  </li>
                                  <li style={{ marginLeft: "4px" }}>
                                    <p className="card-grey-text">
                                      You own your data, and can withdraw
                                      consent to let us collect it at any time.
                                      You may remove your bank account from bank
                                      connections.
                                    </p>
                                  </li>
                                  <li className="card-white-text">
                                    - We never sell or share your data unless
                                    you specify otherwise.
                                  </li>
                                  <li style={{ marginLeft: "4px" }}>
                                    <p className="card-grey-text">
                                      To learn more about SimpliCapital handles
                                      your data, see our SimpliCapital{" "}
                                      <a
                                        href="https://simplicapital.ai/privacy-policy/"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        style={{
                                          cursor: "pointer",
                                          color: "rgb(51, 194, 223)",
                                        }}
                                      >
                                        Privacy Policy
                                      </a>
                                      .
                                    </p>
                                  </li>
                                </StyledList>
                              </Card.Body>
                            </Card>
                            <Card style={{ border: "1px solid #6d6d6d" }}>
                              <Card.Body>
                                <p className="card-heading">
                                  Allow SimpliCapital to collect and use data
                                  from your bank
                                </p>
                                <p>
                                  SimpliCapital requires your consent to access
                                  the following from your bank account for the
                                  next 90 days.
                                </p>
                                <p
                                  style={{
                                    fontSize: "larger",
                                    fontWeight: "bold",
                                    color: "rgb(51, 194, 223)",
                                  }}
                                >
                                  Data we'll collect
                                </p>
                                <li className="card-white-text">
                                  - Contact and party details
                                </li>
                                <li
                                  style={{ marginLeft: "4px" }}
                                  className="card-grey-text"
                                >
                                  The full legal name of account holder
                                </li>
                                <li
                                  style={{ marginLeft: "4px" }}
                                  className="card-grey-text"
                                >
                                  {" "}
                                  Address
                                </li>
                                <li
                                  style={{ marginLeft: "4px" }}
                                  className="card-grey-text"
                                >
                                  {" "}
                                  Telephone number
                                </li>
                                <li
                                  style={{ marginLeft: "4px" }}
                                  className="card-grey-text"
                                >
                                  {" "}
                                  Email address
                                </li>
                                <br />
                                <li className="card-white-text">
                                  - Your account details
                                </li>
                                <li
                                  style={{ marginLeft: "4px" }}
                                  className="card-grey-text"
                                >
                                  Account names
                                </li>
                                <li
                                  style={{ marginLeft: "4px" }}
                                  className="card-grey-text"
                                >
                                  Currency
                                </li>
                                <li
                                  style={{ marginLeft: "4px" }}
                                  className="card-grey-text"
                                >
                                  Types
                                </li>
                                <li
                                  style={{ marginLeft: "4px" }}
                                  className="card-grey-text"
                                >
                                  Numbers
                                </li>
                                <li
                                  style={{ marginLeft: "4px" }}
                                  className="card-grey-text"
                                >
                                  Sort codes
                                </li>
                                <li
                                  style={{ marginLeft: "4px" }}
                                  className="card-grey-text"
                                >
                                  Balances
                                </li>
                                <li
                                  style={{ marginLeft: "4px" }}
                                  className="card-grey-text"
                                >
                                  Credit limits
                                </li>
                                <br />
                                <li className="card-white-text">
                                  - Your account transactions
                                </li>
                                <li
                                  style={{ marginLeft: "4px" }}
                                  className="card-grey-text"
                                >
                                  Historical and current transactions
                                </li>
                                <li
                                  style={{ marginLeft: "4px" }}
                                  className="card-grey-text"
                                >
                                  Transaction types
                                </li>
                                <li
                                  style={{ marginLeft: "4px" }}
                                  className="card-grey-text"
                                >
                                  Amounts
                                </li>
                                <li
                                  style={{ marginLeft: "4px" }}
                                  className="card-grey-text"
                                >
                                  Dates and descriptions
                                </li>
                                <br />
                                <li className="card-white-text">
                                  - Your statements
                                </li>
                                <li
                                  style={{ marginLeft: "4px" }}
                                  className="card-grey-text"
                                >
                                  Periodic account statements
                                </li>
                                <hr />
                                <p className="card-heading">
                                  How we'll use the data we collect
                                </p>
                                <p className="card-grey-text">
                                  Your deposits, account details, and
                                  transactions will be automatically imported
                                  into QuickBooks.
                                </p>
                              </Card.Body>
                            </Card>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "flex-end",
                              }}
                            >
                              <BasicButton
                                onClick={() => setLetsGo(!letsGo)}
                                className="card-button-grey"
                                style={{ background: "#3a3b48" }}
                              >
                                Back
                              </BasicButton>
                              <BasicButton
                                onClick={() => setUserConsent(!userConsent)}
                                className="card-button-blue"
                              >
                                Agree
                              </BasicButton>
                            </div>
                            <hr className="horizontal-divider-color" />
                            <Label
                              style={{
                                justifyContent: "center",
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <LockIcon />
                              <span style={{ marginLeft: "0.4rem" }}>
                                At SimpliCapital, privacy and security of your
                                information are top priorities.
                              </span>
                            </Label>
                          </>
                        )}
                        {letsGo && userConsent && (
                          <>
                            <div className="modal-header-bank-connections">
                              <img alt="SimpliCapital" src={SCLogo}></img>
                              <span className="modal-header-bank-connections-text">
                                Log into your bank
                              </span>
                            </div>
                            <Card style={{ border: "1px solid #6d6d6d" }}>
                              <Card.Body>
                                <span className="card-grey-text">
                                  Let's sign in to your account to share your
                                  transactions with SimpliCapital. Then we'll
                                  bring you back here to finalise your settings.
                                </span>
                              </Card.Body>
                            </Card>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "flex-end",
                              }}
                            >
                              <BasicButton
                                onClick={() => setUserConsent(!userConsent)}
                                className="card-button-grey"
                                style={{ background: "#3a3b48" }}
                              >
                                Back
                              </BasicButton>
                              <PlaidLink
                                style={plaidLinkStyles}
                                active={showPopover} // Example prop for CashButton
                                token={publicLinkToken}
                                onSuccess={(publicToken, metadata) => {
                                  getAccessToken(publicToken);
                                }}
                                // Add other props as needed
                              >
                                Log in
                              </PlaidLink>
                            </div>
                            <hr className="horizontal-divider-color" />
                            <Label
                              style={{
                                justifyContent: "center",
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <LockIcon />
                              <span style={{ marginLeft: "0.4rem" }}>
                                At SimpliCapital, privacy and security of your
                                information are top priorities.
                              </span>
                            </Label>
                          </>
                        )}
                      </Modal.Body>
                    </div>
                  </StyledModal>
                  </div>
                </Modal>
              )}
              <Formik
                initialValues={
                  generalSettingsData
                    ? {
                        // departments: departments,
                        companyName: generalSettingsData?.company,
                        applicationStartDate: moment(
                          generalSettingsData?.startDate
                        ).format("MM-DD-YYYY"),
                        // homeCurrency: generalSettingsData?.homeCurrency,
                        homeCurrency: "USD",
                        costOfCapital: generalSettingsData?.capitalCost,
                        revenueGroup: generalSettingsData?.revenueGroup,
                        sector: generalSettingsData?.sector,
                        industry: generalSettingsData?.industry,
                        billPayments: generalSettingsData?.billPayment,
                        timeZone: generalSettingsData?.timezone,
                        autoPaymentsTime: generalSettingsData.autoPaymentsTime,
                        updateForecastModels: {
                          type:
                            generalSettingsData?.updateForecastModels?.type ||
                            "weekly",
                          day: generalSettingsData?.updateForecastModels?.day,
                          time: generalSettingsData?.updateForecastModels?.time,
                        },
                        cashThresholds: {
                          daily: generalSettingsData?.cashThresholds?.daily,
                          weekly: generalSettingsData?.cashThresholds?.weekly,
                          monthly: generalSettingsData?.cashThresholds?.monthly,
                        },
                        ordersThresholds: {
                          daily: generalSettingsData?.ordersThresholds?.daily,
                          weekly: generalSettingsData?.ordersThresholds?.weekly,
                          monthly:
                            generalSettingsData?.ordersThresholds?.monthly,
                        },
                        collectionsThresholds: {
                          daily:
                            generalSettingsData?.collectionsThresholds?.daily,
                          weekly:
                            generalSettingsData?.collectionsThresholds?.weekly,
                          monthly:
                            generalSettingsData?.collectionsThresholds?.monthly,
                        },
                        receivablesThresholds: {
                          daily:
                            generalSettingsData?.receivablesThresholds?.daily,
                          weekly:
                            generalSettingsData?.receivablesThresholds?.weekly,
                          monthly:
                            generalSettingsData?.receivablesThresholds?.monthly,
                        },
                        payablesThresholds: {
                          daily: generalSettingsData?.payablesThresholds?.daily,
                          weekly:
                            generalSettingsData?.payablesThresholds?.weekly,
                          monthly:
                            generalSettingsData?.payablesThresholds?.monthly,
                        },
                        profileImage: null,
                        showSalesRep: generalSettingsData?.showSalesRep,
                        showJobType: generalSettingsData?.showJobType,
                        showCustomerMemo: generalSettingsData?.showCustomerMemo,
                      }
                    : {
                        // departments: departments,
                        companyName: "",
                        applicationStartDate: "",
                        homeCurrency: "USD",
                        costOfCapital: "",
                        revenueGroup: "",
                        sector: "",
                        industry: "",
                        billPayments: "",
                        timeZone: "",
                        autoPaymentsTime: "",
                        updateForecastModels: {
                          type: "",
                          day: "",
                          time: "",
                        },
                        cashThresholds: {
                          daily: 0,
                          weekly: 0,
                          monthly: 0,
                        },
                        ordersThresholds: {
                          daily: 0,
                          weekly: 0,
                          monthly: 0,
                        },
                        collectionsThresholds: {
                          daily: 0,
                          weekly: 0,
                          monthly: 0,
                        },
                        receivablesThresholds: {
                          daily: 0,
                          weekly: 0,
                          monthly: 0,
                        },
                        payablesThresholds: {
                          daily: 0,
                          weekly: 0,
                          monthly: 0,
                        },
                        profileImage: null,
                        showSalesRep: false,
                        showJobType: false,
                        showCustomerMemo: false,
                      }
                }
                enableReinitialize={true}
                validationSchema={Yup.object().shape({
                  costOfCapital: Yup.number()
                    .min(0, "Number cant be Negative")
                    .max(100, "Please enter number within 1-100 range")
                    .nullable(),
                  companyName: Yup.string()
                    .trim()
                    .required("Please enter Company Name"),
                  profileImage: Yup.mixed().test(
                    "fileSize",
                    "Image size too large",
                    (value) =>
                      value === null || (value && value.size <= 5242880)
                  ),
                  showSalesRep: Yup.boolean(),
                  showCustomerMemo: Yup.boolean(),
                  showJobType: Yup.boolean(),
                  departments: Yup.array().of(
                    Yup.object().shape({
                      thresholds: Yup.object().shape({
                        cash: Yup.array().of(
                          Yup.object().shape({
                            daily: Yup.number().nullable(),
                            weekly: Yup.number().nullable(),
                            monthly: Yup.number().nullable(),
                          })
                        ),
                      }),
                      children: Yup.array().of(
                        Yup.object().shape({
                          thresholds: Yup.object().shape({
                            cash: Yup.array().of(
                              Yup.object().shape({
                                daily: Yup.number()
                                  .test({
                                    name: "max",
                                    exclusive: false,
                                    params: {},
                                    message: "value cant be more than parent",
                                    test: function (value) {
                                      const { from } = this;
                                      return (
                                        value === undefined ||
                                        value === null ||
                                        value === 0 ||
                                        (value &&
                                          value <=
                                            from[3].value.thresholds.cash[0]
                                              .daily)
                                      );
                                    },
                                  })
                                  .nullable(),

                                weekly: Yup.number()
                                  .test({
                                    name: "max",
                                    exclusive: false,
                                    params: {},
                                    message: "value cant be more than parent",
                                    test: function (value) {
                                      const { from } = this;

                                      return (
                                        value === undefined ||
                                        value === null ||
                                        value === 0 ||
                                        (value &&
                                          value <=
                                            from[3].value.thresholds.cash[0]
                                              .weekly)
                                      );
                                    },
                                  })
                                  .nullable(),

                                monthly: Yup.number()
                                  .test({
                                    name: "max",
                                    exclusive: false,
                                    params: {},
                                    message: "value cant be more than parent",
                                    test: function (value) {
                                      const { from } = this;

                                      return (
                                        value === undefined ||
                                        value === null ||
                                        value === 0 ||
                                        (value &&
                                          value <=
                                            from[3].value.thresholds.cash[0]
                                              .monthly)
                                      );
                                    },
                                  })
                                  .nullable(),
                              })
                            ),
                            orders: Yup.array().of(
                              Yup.object().shape({
                                daily: Yup.number()
                                  .test({
                                    name: "max",
                                    exclusive: false,
                                    params: {},
                                    message: "value cant be more than parent",
                                    test: function (value) {
                                      const { from } = this;

                                      return (
                                        value === undefined ||
                                        value === null ||
                                        value === 0 ||
                                        (value &&
                                          value <=
                                            from[3].value.thresholds.orders[0]
                                              .daily)
                                      );
                                    },
                                  })
                                  .nullable(),

                                weekly: Yup.number()
                                  .test({
                                    name: "max",
                                    exclusive: false,
                                    params: {},
                                    message: "value cant be more than parent",
                                    test: function (value) {
                                      const { from } = this;

                                      return (
                                        value === undefined ||
                                        value === null ||
                                        value === 0 ||
                                        (value &&
                                          value <=
                                            from[3].value.thresholds.orders[0]
                                              .weekly)
                                      );
                                    },
                                  })
                                  .nullable(),

                                monthly: Yup.number()
                                  .test({
                                    name: "max",
                                    exclusive: false,
                                    params: {},
                                    message: "value cant be more than parent",
                                    test: function (value) {
                                      const { from } = this;

                                      return (
                                        value === undefined ||
                                        value === null ||
                                        value === 0 ||
                                        (value &&
                                          value <=
                                            from[3].value.thresholds.orders[0]
                                              .monthly)
                                      );
                                    },
                                  })
                                  .nullable(),
                              })
                            ),
                            collections: Yup.array().of(
                              Yup.object().shape({
                                daily: Yup.number()
                                  .test({
                                    name: "max",
                                    exclusive: false,
                                    params: {},
                                    message: "value cant be more than parent",
                                    test: function (value) {
                                      const { from } = this;

                                      return (
                                        value === undefined ||
                                        value === null ||
                                        value === 0 ||
                                        (value &&
                                          value <=
                                            from[3].value.thresholds
                                              .collections[0].daily)
                                      );
                                    },
                                  })
                                  .nullable(),

                                weekly: Yup.number()
                                  .test({
                                    name: "max",
                                    exclusive: false,
                                    params: {},
                                    message: "value cant be more than parent",
                                    test: function (value) {
                                      const { from } = this;

                                      return (
                                        value === undefined ||
                                        value === null ||
                                        value === 0 ||
                                        (value &&
                                          value <=
                                            from[3].value.thresholds
                                              .collections[0].weekly)
                                      );
                                    },
                                  })
                                  .nullable(),

                                monthly: Yup.number()
                                  .test({
                                    name: "max",
                                    exclusive: false,
                                    params: {},
                                    message: "value cant be more than parent",
                                    test: function (value) {
                                      const { from } = this;

                                      return (
                                        value === undefined ||
                                        value === null ||
                                        value === 0 ||
                                        (value &&
                                          value <=
                                            from[3].value.thresholds
                                              .collections[0].monthly)
                                      );
                                    },
                                  })
                                  .nullable(),
                              })
                            ),
                            receivables: Yup.array().of(
                              Yup.object().shape({
                                daily: Yup.number()
                                  .test({
                                    name: "max",
                                    exclusive: false,
                                    params: {},
                                    message: "value cant be more than parent",
                                    test: function (value) {
                                      const { from } = this;

                                      return (
                                        value === undefined ||
                                        value === null ||
                                        value === 0 ||
                                        (value &&
                                          value <=
                                            from[3].value.thresholds
                                              .receivables[0].daily)
                                      );
                                    },
                                  })
                                  .nullable(),

                                weekly: Yup.number()
                                  .test({
                                    name: "max",
                                    exclusive: false,
                                    params: {},
                                    message: "value cant be more than parent",
                                    test: function (value) {
                                      const { from } = this;

                                      return (
                                        value === undefined ||
                                        value === null ||
                                        value === 0 ||
                                        (value &&
                                          value <=
                                            from[3].value.thresholds
                                              .receivables[0].weekly)
                                      );
                                    },
                                  })
                                  .nullable(),

                                monthly: Yup.number()
                                  .test({
                                    name: "max",
                                    exclusive: false,
                                    params: {},
                                    message: "value cant be more than parent",
                                    test: function (value) {
                                      const { from } = this;

                                      return (
                                        value === undefined ||
                                        value === null ||
                                        value === 0 ||
                                        (value &&
                                          value <=
                                            from[3].value.thresholds
                                              .receivables[0].monthly)
                                      );
                                    },
                                  })
                                  .nullable(),
                              })
                            ),
                            payables: Yup.array().of(
                              Yup.object().shape({
                                daily: Yup.number()
                                  .test({
                                    name: "max",
                                    exclusive: false,
                                    params: {},
                                    message: "value cant be more than parent",
                                    test: function (value) {
                                      const { from } = this;

                                      return (
                                        value === undefined ||
                                        value === null ||
                                        value === 0 ||
                                        (value &&
                                          value <=
                                            from[3].value.thresholds.payables[0]
                                              .daily)
                                      );
                                    },
                                  })
                                  .nullable(),

                                weekly: Yup.number()
                                  .test({
                                    name: "max",
                                    exclusive: false,
                                    params: {},
                                    message: "value cant be more than parent",
                                    test: function (value) {
                                      const { from } = this;

                                      return (
                                        value === undefined ||
                                        value === null ||
                                        value === 0 ||
                                        (value &&
                                          value <=
                                            from[3].value.thresholds.payables[0]
                                              .weekly)
                                      );
                                    },
                                  })
                                  .nullable(),

                                monthly: Yup.number()
                                  .test({
                                    name: "max",
                                    exclusive: false,
                                    params: {},
                                    message: "value cant be more than parent",
                                    test: function (value) {
                                      const { from } = this;

                                      return (
                                        value === undefined ||
                                        value === null ||
                                        value === 0 ||
                                        (value &&
                                          value <=
                                            from[3].value.thresholds.payables[0]
                                              .monthly)
                                      );
                                    },
                                  })
                                  .nullable(),
                              })
                            ),
                          }),
                        })
                      ),
                    })
                  ),
                })}
                onSubmit={(values, { setSubmitting }) => {
                  try {
                    console.log("submiting");
                    setSubmitting(true);
                    // check if daily is selected and time is not selected
                    if (values.updateForecastModels.type === "daily") {
                      if (values.updateForecastModels.time === " 00:00")
                        values.updateForecastModels.time = "";
                    }
                    // check if weekly is selected and time or day is not selected
                    if (values.updateForecastModels.type === "weekly") {
                      if (values.updateForecastModels.time == "") {
                        values.updateForecastModels.time = "00:00";
                      }
                      if (values.updateForecastModels.day === "") {
                        values.updateForecastModels.day = "1";
                      }
                      handleAddGeneralSettings(values);
                    }
                    setSubmitting(false);
                  } catch (e) {
                    console.log(e);
                  }
                }}
              >
                {({ values, errors, touched, handleSubmit, setFieldValue }) => (
                  <Form onSubmit={handleSubmit} className="mx-auto">
                    {/* <JSONPretty
                      style={{ color: "white" }}
                      data={values}
                    ></JSONPretty> */}

                    <StyledCard>
                      <Card>
                        <Card.Body>
                          <OuterDiv>
                            <ErrorHandlingComponent errors={errorMessage} />
                            {Object.values(errors).length > 0
                              ? Object.values(errors).map((errorBody) => {
                                  <ErrorHandlingComponent errors={errorBody} />;
                                })
                              : null}
                            <Row>
                              <Authorise
                                showUnAuthorisedComponenet={<></>}
                                allowedRoles={["admin"]}
                              >
                                <Col sm={3}>
                                  {companyEdit ? (
                                    <InputwithLabel
                                      name="companyName"
                                      type="text"
                                      label="Company Name"
                                      defaultValue={values.companyName}
                                    />
                                  ) : (
                                    <>
                                      <StyledLabel style={{ display: "Block" }}>
                                        Company Name
                                      </StyledLabel>
                                      <span className="company-name">
                                        {values.companyName}{" "}
                                      </span>
                                      <EditIcon
                                        className="company-edit"
                                        onClick={() =>
                                          setCompanyEdit(!companyEdit)
                                        }
                                      />
                                    </>
                                  )}
                                </Col>
                                <Col sm={6}></Col>
                                <Col sm={3}>
                                  <Logo>
                                    <span className="logo-heading">
                                      Your Logo
                                    </span>{" "}
                                    <label
                                      style={{ cursor: "pointer" }}
                                      htmlFor="fileUpload"
                                      className="logo-edit"
                                    >
                                      <span className="logo-edit">
                                        <EditIcon />
                                        Edit Logo
                                      </span>
                                      <input
                                        hidden
                                        id="fileUpload"
                                        name="profileImage"
                                        type="file"
                                        accept="image/png,image/jpeg,image/jpg"
                                        onChange={(event) => {
                                          setFieldValue(
                                            "profileImage",
                                            event.currentTarget.files[0]
                                          );
                                        }}
                                      ></input>
                                    </label>
                                    <img
                                      src={
                                        values.profileImage
                                          ? URL.createObjectURL(
                                              values.profileImage
                                            )
                                          : generalSettingsData?.bucketUrl
                                      }
                                      alt="logo"
                                      style={{ objectFit: "contain" }}
                                    ></img>
                                  </Logo>
                                  {errors.profileImage &&
                                  touched.profileImage ? (
                                    <p className="custom-error">
                                      {errors.profileImage}
                                    </p>
                                  ) : null}
                                </Col>
                              </Authorise>
                            </Row>
                            <StyledAccordion alwaysOpen>
                              <Authorise
                                showUnAuthorisedComponenet={<></>}
                                allowedRoles={["admin"]}
                              >
                                <Accordion.Item eventKey="0">
                                  <Accordion.Header>
                                    Product Settings
                                    <StyledLabel
                                      style={{
                                        marginLeft: "20px",
                                        marginBottom: "0px",
                                      }}
                                    >
                                      We’ll use this to benchmark your KPIs with
                                      your industry peers
                                    </StyledLabel>
                                  </Accordion.Header>
                                  <Accordion.Body>
                                    <Row>
                                      <Col sm={3}>
                                        <DatePickerField
                                          name="applicationStartDate"
                                          label="Application start date"
                                          dateFormat="dd-MM-yyyy"
                                          disabled={isEdited}
                                        />
                                      </Col>
                                      <Col sm={9}></Col>
                                    </Row>
                                    <Row>
                                      <Col sm={3}>
                                        <SimpleSelect
                                          label={
                                            <>
                                              <span
                                                style={{ marginRight: "5px" }}
                                              >
                                                Home Currency
                                              </span>
                                              <InfoIcon
                                                data-tip="This is the base currency used across all reporting"
                                                data-for="info"
                                              />
                                              <Tooltip
                                                place="right"
                                                id="info"
                                              />
                                            </>
                                          }
                                          name="homeCurrency"
                                          defaultValue={values.homeCurrency}
                                          disabled={true}
                                          options={[
                                            {
                                              label: "USD",
                                              value: "USD",
                                              id: "1",
                                            },
                                            {
                                              label: "EUR",
                                              value: "EUR",
                                              id: "2",
                                            },
                                            {
                                              label: "INR",
                                              value: "INR",
                                              id: "3",
                                            },
                                          ]}
                                        />
                                      </Col>
                                      {/* <Col sm={3}>
                                      <SimpleSelect
                                        label="Time Zone"
                                        name="timeZone"
                                        value={values.timeZone}
                                        options={timezonesArray}
                                      />
                                    </Col> */}
                                      <Col sm={3}>
                                        <InputwithLabel
                                          name="costOfCapital"
                                          type="number"
                                          label="Cost of Capital %"
                                        />
                                      </Col>
                                    </Row>
                                    <Row>
                                      {isLoadingSectors ? (
                                        <Loader />
                                      ) : (
                                        <>
                                          <Col sm={3}>
                                            <SimpleSelect
                                              name="sector"
                                              label="Sector"
                                              defaultValue={values.sector}
                                              options={sectorOptions}
                                              onChange={(e) => {
                                                handleSector(e.target.value);
                                                setFieldValue(
                                                  "sector",
                                                  e.target.value
                                                );
                                                setFieldValue("industry", "");
                                              }}
                                            />
                                          </Col>
                                          {!industrychange && (
                                            <Col sm={3}>
                                              <SimpleSelect
                                                name="industry"
                                                label="Industry"
                                                defaultValue={values.industry}
                                                options={
                                                  subSectors[0]
                                                    ? subSectors[0]
                                                    : []
                                                }
                                                onChange={(e) => {
                                                  setFieldValue(
                                                    "industry",
                                                    e.target.value
                                                  );
                                                }}
                                                onClick={(e) => {
                                                  setFieldValue(
                                                    "industry",
                                                    e.target.value
                                                  );
                                                }}
                                              />
                                            </Col>
                                          )}
                                        </>
                                      )}
                                      <Col sm={3}>
                                        <SimpleSelect
                                          name="revenueGroup"
                                          label="Revenue Group"
                                          defaultValue={values.revenueGroup}
                                          options={[
                                            {
                                              label: "0 - $100M",
                                              value: "0 - $100M",
                                            },
                                            {
                                              label: "$100M - $250M",
                                              value: "$100M - $250M",
                                            },
                                            {
                                              label: "$250M - $500M",
                                              value: "$250M - $500M",
                                            },
                                            {
                                              label: "$500M - $1B",
                                              value: "$500M - $1B",
                                            },
                                            {
                                              label: "$1B+",
                                              value: "$1B+",
                                            },
                                          ]}
                                        />
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col sm={3}>
                                        <StyledLabel>
                                          Show Sales Representative details
                                          <InfoIcon
                                            data-tip="This setting will enable sales representative details in actual receivables page"
                                            data-for="info"
                                            style={{ marginLeft: "5px" }}
                                          />
                                          <Tooltip place="right" id="info" />
                                        </StyledLabel>
                                        <ToggleButtonComponent
                                          name="status"
                                          defaultValue={values.showSalesRep}
                                          value={values.showSalesRep}
                                          setToggle={(e) =>
                                            setFieldValue("showSalesRep", e)
                                          }
                                        />
                                      </Col>
                                      <Col sm={3}>
                                        <StyledLabel>
                                          Filter Receivable By Job Type
                                          <InfoIcon
                                            data-tip="This setting will enable Filter data By Job Type on Receivables Page Only If you have Job Type Available on Receivables"
                                            data-for="info"
                                            style={{ marginLeft: "5px" }}
                                          />
                                          <Tooltip place="right" id="info" />
                                        </StyledLabel>
                                        <ToggleButtonComponent
                                          name="status"
                                          defaultValue={values.showJobType}
                                          value={values.showJobType}
                                          setToggle={(e) =>
                                            setFieldValue("showJobType", e)
                                          }
                                        />
                                      </Col>
                                      <Col sm={3}>
                                        <StyledLabel>
                                          Show Customer Memo
                                          <InfoIcon
                                            data-tip="This setting will enable Customer memo on Receivables Page"
                                            data-for="info"
                                            style={{ marginLeft: "5px" }}
                                          />
                                          <Tooltip place="right" id="info" />
                                        </StyledLabel>
                                        <ToggleButtonComponent
                                          name="showCustomerMemo"
                                          defaultValue={values.showCustomerMemo}
                                          value={values.showCustomerMemo}
                                          setToggle={(e) =>
                                            setFieldValue("showCustomerMemo", e)
                                          }
                                        />
                                      </Col>
                                    </Row>
                                  </Accordion.Body>
                                </Accordion.Item>
                              </Authorise>
                              {/* <Accordion.Item eventKey="1">
                                <Authorise
                                  showUnAuthorisedComponenet={<></>}
                                  allowedOperation="read-write"
                                  allowedPermission="SETTINGS_GS"
                                  allowedRoles={["admin", "read-write"]}
                                >
                                  <Accordion.Header>
                                    Do you want to define thresholds for alerts
                                    and notifications?
                                  </Accordion.Header>
                                </Authorise>
                                <Accordion.Body>
                                  <Row>
                                    <Authorise
                                      showUnAuthorisedComponenet={<></>}
                                      allowedOperation="read-write"
                                      allowedPermission="SETTINGS_GS"
                                      allowedRoles={["admin", "read-write"]}
                                    >
                                      <Col>
                                        <div>
                                          <StyledLabel>Thresholds</StyledLabel>
                                        </div>
                                        <CashButton
                                          {...props}
                                          active={thresholds === "cash"}
                                          type="button"
                                          width="110px"
                                          className="button-border"
                                          onClick={() => {
                                            HandleThresholds("cash");
                                          }}
                                        >
                                          Cash
                                        </CashButton>
                                        <OrderButton
                                          {...props}
                                          active={thresholds === "orders"}
                                          type="button"
                                          width="110px"
                                          className="button-border"
                                          onClick={() => {
                                            HandleThresholds("orders");
                                          }}
                                        >
                                          Orders
                                        </OrderButton>
                                        <CollectionButton
                                          {...props}
                                          active={thresholds === "collections"}
                                          type="button"
                                          width="110px"
                                          className="button-border"
                                          onClick={() =>
                                            HandleThresholds("collections")
                                          }
                                        >
                                          Collections
                                        </CollectionButton>
                                        <ReceivablesButton
                                          {...props}
                                          active={thresholds === "receivables"}
                                          type="button"
                                          width="110px"
                                          className="button-border"
                                          onClick={() =>
                                            HandleThresholds("receivables")
                                          }
                                        >
                                          Receivables
                                        </ReceivablesButton>
                                        <PayablesButton
                                          {...props}
                                          active={thresholds === "payables"}
                                          type="button"
                                          width="110px"
                                          className="button-border"
                                          onClick={() =>
                                            HandleThresholds("payables")
                                          }
                                        >
                                          Payables
                                        </PayablesButton>
                                      </Col>
                                    </Authorise>
                                  </Row>
                                  <ThresholdSection>
                                    <Row>
                                      <Authorise
                                        showUnAuthorisedComponenet={<></>}
                                        allowedRoles={["admin"]}
                                      >
                                        <Col sm={12}>
                                          <div style={{ marginTop: "25px" }}>
                                            <StyledLabel>Overall</StyledLabel>
                                          </div>
                                        </Col>
                                        <Col sm={2}>
                                          <InputwithLabel
                                            name={`${thresholds}Thresholds.daily`}
                                            type="number"
                                            label="Daily"
                                            onWheel={(e) => {
                                              e.target.blur();
                                            }}
                                          />
                                        </Col>
                                        <Col sm={2}>
                                          <InputwithLabel
                                            name={`${thresholds}Thresholds.weekly`}
                                            type="number"
                                            label="Weekly"
                                            onWheel={(e) => {
                                              e.target.blur();
                                            }}
                                          />
                                        </Col>
                                        <Col sm={2}>
                                          <InputwithLabel
                                            name={`${thresholds}Thresholds.monthly`}
                                            type="number"
                                            label="Monthly"
                                            onWheel={(e) => {
                                              e.target.blur();
                                            }}
                                          />
                                        </Col>
                                      </Authorise>
                                    </Row>
                                    {forFutureRelease && <Row style={{ marginTop: "10px" }}>
                                      <Authorise
                                        showUnAuthorisedComponenet={<></>}
                                        allowedOperation="read-write"
                                        allowedPermission="SETTINGS_GS"
                                        allowedRoles={["admin", "read-write"]}
                                      >
                                        <Col sm={6}></Col>
                                        <Col sm={6}>
                                          <StyledLabel
                                            style={{ width: "65px" }}
                                          >
                                            Daily
                                          </StyledLabel>
                                          <StyledLabel
                                            style={{ width: "65px" }}
                                          >
                                            Weekly
                                          </StyledLabel>
                                          <StyledLabel
                                            style={{ width: "65px" }}
                                          >
                                            Monthly
                                          </StyledLabel>
                                        </Col>
                                      </Authorise>
                                    </Row> }
                                    {forFutureRelease && <Authorise
                                      showUnAuthorisedComponenet={<></>}
                                      allowedOperation="read-write"
                                      allowedPermission="SETTINGS_GS"
                                      allowedRoles={["admin", "read-write"]}
                                    >
                                      {isLoadingDepartments ? (
                                        <Loader />
                                      ) : (
                                        <FieldArray
                                          name="departments"
                                          render={(fieldArrayHelpers) => (
                                            <>
                                              {departments?.map(
                                                (department, index) => {
                                                  return (
                                                    <>
                                                      <BusinessSection
                                                        key={department.id}
                                                      >
                                                        <Row>
                                                          <Col sm={6}>
                                                            {
                                                              department.fullyQualifiedName
                                                            }
                                                          </Col>

                                                          <Col sm={6}>
                                                            <>
                                                              {department
                                                                .thresholds[
                                                                thresholds
                                                              ] ? (
                                                                <>
                                                                  <Field
                                                                    name={`departments[${index}].thresholds.${thresholds}.[0].daily`}
                                                                    type="number"
                                                                    onWheel={(
                                                                      e
                                                                    ) => {
                                                                      e.target.blur();
                                                                    }}
                                                                  />

                                                                  <Field
                                                                    name={`departments[${index}].thresholds.${thresholds}.[0].weekly`}
                                                                    type="number"
                                                                    onWheel={(
                                                                      e
                                                                    ) => {
                                                                      e.target.blur();
                                                                    }}
                                                                  />

                                                                  <Field
                                                                    name={`departments[${index}].thresholds.${thresholds}.[0].monthly`}
                                                                    type="number"
                                                                    onWheel={(
                                                                      e
                                                                    ) => {
                                                                      e.target.blur();
                                                                    }}
                                                                  />
                                                                </>
                                                              ) : (
                                                                <p>
                                                                  {thresholds}{" "}
                                                                  key missing in
                                                                  API data
                                                                </p>
                                                              )}
                                                            </>
                                                          </Col>
                                                        </Row>
                                                      </BusinessSection>
                                                      <div>
                                                        {department.children.map(
                                                          (
                                                            childDepartment,
                                                            childIndex
                                                          ) => (
                                                            <BusinessSection
                                                              key={
                                                                childDepartment.id
                                                              }
                                                            >
                                                              <Row>
                                                                <Col sm={6}>
                                                                  {
                                                                    childDepartment.fullyQualifiedName
                                                                  }
                                                                </Col>

                                                                <Col sm={6}>
                                                                  <>
                                                                    {childDepartment
                                                                      .thresholds[
                                                                      thresholds
                                                                    ] ? (
                                                                      <>
                                                                        <Field
                                                                          name={`departments[${index}].children[${childIndex}].thresholds.${thresholds}.[0].daily`}
                                                                          type="number"
                                                                          onWheel={(
                                                                            e
                                                                          ) => {
                                                                            e.target.blur();
                                                                          }}
                                                                        />

                                                                        <Field
                                                                          name={`departments[${index}].children[${childIndex}].thresholds.${thresholds}.[0].weekly`}
                                                                          type="number"
                                                                          onWheel={(
                                                                            e
                                                                          ) => {
                                                                            e.target.blur();
                                                                          }}
                                                                        />

                                                                        <Field
                                                                          name={`departments[${index}].children[${childIndex}].thresholds.${thresholds}.[0].monthly`}
                                                                          type="number"
                                                                          onWheel={(
                                                                            e
                                                                          ) => {
                                                                            e.target.blur();
                                                                          }}
                                                                        />
                                                                        <div>
                                                                          <p
                                                                            style={{
                                                                              color:
                                                                                " #ff7171",
                                                                            }}
                                                                          >
                                                                            <ErrorMessage
                                                                              name={`departments[${index}].children[${childIndex}].thresholds.${thresholds}.[0].daily`}
                                                                            />
                                                                          </p>
                                                                          <p
                                                                            style={{
                                                                              color:
                                                                                " #ff7171",
                                                                            }}
                                                                          >
                                                                            <ErrorMessage
                                                                              name={`departments[${index}].children[${childIndex}].thresholds.${thresholds}.[0].weekly`}
                                                                            />
                                                                          </p>
                                                                          <p
                                                                            style={{
                                                                              color:
                                                                                " #ff7171",
                                                                            }}
                                                                          >
                                                                            <ErrorMessage
                                                                              name={`departments[${index}].children[${childIndex}].thresholds.${thresholds}.[0].monthly`}
                                                                            />
                                                                          </p>
                                                                        </div>
                                                                      </>
                                                                    ) : (
                                                                      <p>
                                                                        {
                                                                          thresholds
                                                                        }{" "}
                                                                        key
                                                                        missing
                                                                        in API
                                                                        data
                                                                      </p>
                                                                    )}
                                                                  </>
                                                                </Col>
                                                              </Row>
                                                            </BusinessSection>
                                                          )
                                                        )}
                                                      </div>
                                                    </>
                                                  );
                                                }
                                              )}
                                            </>
                                          )}
                                        />
                                      )}
                                    </Authorise> }
                                  </ThresholdSection>
                                </Accordion.Body>
                              </Accordion.Item> */}
                              <Authorise
                                showUnAuthorisedComponenet={<></>}
                                allowedRoles={["admin"]}
                              >
                                <Accordion.Item eventKey="2">
                                  <Accordion.Header>
                                    Set your preferred frequency to train and
                                    run models.
                                    {/*Set your preferred frequency to make auto payments and train and run
                                    models.
                                     Set your preferred frequency to receive
                                    reminders, make auto payments and read your
                                    data. */}
                                  </Accordion.Header>
                                  <Accordion.Body>
                                    <Row>
                                      <Col sm={3}>
                                        <SimpleSelect
                                          label="Time Zone"
                                          name="timeZone"
                                          defaultValue={values.timeZone}
                                          options={timezonesArray}
                                        />
                                      </Col>
                                      <Col sm={3}>
                                        {forFutureRelease && (
                                          <SimpleSelect
                                            name="billPayments"
                                            label="Specify account for all Bill Payments"
                                            defaultValue={values.billPayments}
                                            options={[
                                              {
                                                label: "Amex Card",
                                                value: "Amex Card",
                                              },
                                              {
                                                label: "Mastercard",
                                                value: "Mastercard",
                                              },
                                            ]}
                                          />
                                        )}
                                      </Col>
                                    </Row>
                                    {forFutureRelease && (
                                      <Row>
                                        <Col sm={3}>
                                          {values.autoPaymentsTime ? (
                                            <TimePickerField
                                              icon={true}
                                              name="autoPaymentsTime"
                                              label="Scheduled Time for Auto Payments"
                                              defaultValue={moment(
                                                values.autoPaymentsTime,
                                                "HH:mm"
                                              )}
                                            />
                                          ) : (
                                            <></>
                                          )}
                                        </Col>
                                      </Row>
                                    )}
                                    <Row>
                                      <Col sm={4}>
                                        <StyledLabel>
                                          Update Forecast Models
                                        </StyledLabel>
                                        <Radio
                                          name="updateForecastModels.type"
                                          options={[
                                            // hidden for  sprint 2
                                            // {
                                            //   key: "Daily",
                                            //   value: "daily",
                                            //   id: "1",
                                            // },
                                            {
                                              key: "Weekly",
                                              value: "weekly",
                                              id: "2",
                                            },
                                          ]}
                                          onClick={(e) =>
                                            e.target.value === "daily" &&
                                            setFieldValue(
                                              "updateForecastModels.day",
                                              "0"
                                            )
                                          }
                                        ></Radio>
                                        {values.updateForecastModels.type ===
                                        "daily" ? (
                                          <UpdateForecast>
                                            <TimePickerField
                                              icon={true}
                                              name="updateForecastModels.time"
                                              // defaultValue={moment(
                                              //   values?.updateForecastModels
                                              //     ?.time,
                                              //   "HH:mm"
                                              // )}
                                              defaultValue={
                                                generalSettingsData &&
                                                generalSettingsData
                                                  ?.updateForecastModels
                                                  ?.time !== ""
                                                  ? moment(
                                                      values
                                                        ?.updateForecastModels
                                                        ?.time,
                                                      "HH:mm"
                                                    )
                                                  : ""
                                              }
                                              placeholder="select time"
                                            />
                                          </UpdateForecast>
                                        ) : (
                                          <UpdateForecast>
                                            <SimpleSelect
                                              defaultValue={
                                                values.updateForecastModels.day
                                              }
                                              name="updateForecastModels.day"
                                              options={[
                                                {
                                                  label: "Sunday",
                                                  value: 1,
                                                  id: "1",
                                                },
                                                {
                                                  label: "Monday",
                                                  value: 2,
                                                },
                                                {
                                                  label: "Tuesday",
                                                  value: 3,
                                                },
                                                {
                                                  label: "Wednesday",
                                                  value: 4,
                                                },
                                                {
                                                  label: "Thursday",
                                                  value: 5,
                                                },
                                                {
                                                  label: "Friday",
                                                  value: 6,
                                                },
                                                {
                                                  label: "Saturday",
                                                  value: 7,
                                                },
                                              ]}
                                            />

                                            <TimePickerField
                                              icon={true}
                                              name="updateForecastModels.time"
                                              defaultValue={
                                                generalSettingsData &&
                                                generalSettingsData
                                                  ?.updateForecastModels
                                                  ?.time !== ""
                                                  ? moment(
                                                      values
                                                        ?.updateForecastModels
                                                        ?.time,
                                                      "HH:mm"
                                                    )
                                                  : ""
                                              }
                                              placeholder="select time"
                                              // defaultValue=""
                                            />
                                          </UpdateForecast>
                                        )}
                                      </Col>
                                    </Row>
                                  </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="3">
                                  <Accordion.Header>
                                    Bank Connections
                                    {/*Set your preferred frequency to make auto payments and train and run
                                    models.
                                     Set your preferred frequency to receive
                                    reminders, make auto payments and read your
                                    data. */}
                                  </Accordion.Header>
                                  <Accordion.Body>
                                    <Row>
                                      {showBankConnectButton && (
                                        <>
                                          <StyledLabel
                                            style={{
                                              marginBottom: "20px",
                                              fontSize: "12px",
                                            }}
                                          >
                                            Your connected bank accounts are
                                            listed below.
                                          </StyledLabel>
                                          <ul
                                            style={{
                                              display: "flex",
                                              flexDirection: "column",
                                              width: "100%",
                                              listStyleType: "none",
                                              padding: 0,
                                            }}
                                          >
                                            {bankAccountsData.map(
                                              (elem, ind) => {
                                                return (
                                                  <li
                                                    key={ind}
                                                    style={{
                                                      display: "flex",
                                                      justifyContent:
                                                        "space-between",
                                                      alignItems: "center",
                                                      border:
                                                        "1px solid #6f6f6f",
                                                      marginBottom: "10px",
                                                      padding: "10px",
                                                      backgroundColor:
                                                        "#33333F",
                                                      borderRadius: "6px",
                                                      boxShadow:
                                                        "0px 2px 4px rgba(0, 0, 0, 0.1)",
                                                      fontSize: "14px",
                                                      fontWeight: "bold",
                                                    }}
                                                  >
                                                    <p
                                                      style={{
                                                        flex: 1,
                                                        color: "#ffffff69",
                                                        marginTop: "0px",
                                                        marginBottom: "0px",
                                                      }}
                                                    >
                                                      {elem.bank}
                                                    </p>
                                                    <p
                                                      style={{
                                                        cursor: "pointer",
                                                        flex: 1,
                                                        display: "flex",
                                                        justifyContent:
                                                          "center",
                                                        alignItems: "center",
                                                        marginTop: "0px",
                                                        marginBottom: "0px",
                                                      }}
                                                    >
                                                      <NumberFormat
                                                        style={{
                                                          fontSize: "14px",
                                                          fontWeight: "bold",
                                                        }}
                                                        className="cash-in-bank-settings"
                                                        thousandSeparator={true}
                                                        prefix={"$"}
                                                        value={Math.round(
                                                          elem?.totalAmountInBank
                                                        )}
                                                        displayType="text"
                                                        isNumericString={true}
                                                        decimalScale={2}
                                                      />
                                                    </p>
                                                    <div
                                                      style={{
                                                        flex: 1,
                                                        display: "flex",
                                                        justifyContent:
                                                          "center",
                                                        alignItems: "center",
                                                        marginTop: "0px",
                                                        marginBottom: "0px",
                                                      }}
                                                    >
                                                      <label
                                                        style={{
                                                          fontSize: "14px",
                                                          fontWeight: "bold",
                                                          color: "#808186",
                                                          marginRight: "8px",
                                                          marginTop: "0px",
                                                          marginBottom: "0px",
                                                        }}
                                                      >
                                                        Use for forecast?
                                                      </label>
                                                      <CheckboxWrapper>
                                                        <input
                                                          disabled={
                                                            elem.daysLeftToReAuthorise <=
                                                              90 &&
                                                            elem.daysLeftToReAuthorise >
                                                              0
                                                              ? false
                                                              : true
                                                          }
                                                          type="checkbox"
                                                          label="Use in forecasting?"
                                                          checked={
                                                            elem.useInForecast
                                                          }
                                                          onChange={(e) =>
                                                            updateBankForForecasting(
                                                              elem.useInForecast,
                                                              elem.id
                                                            )
                                                          }
                                                        />
                                                      </CheckboxWrapper>
                                                    </div>
                                                    {elem.daysLeftToReAuthorise >
                                                    0 ? (
                                                      <p
                                                        style={{
                                                          textDecoration:
                                                            "underline",
                                                          color: "#ffffff69",
                                                          cursor: "pointer",
                                                          flex: "1",
                                                          display: "flex",
                                                          justifyContent:
                                                            "center",
                                                          alignItems: "center",
                                                          marginTop: "0px",
                                                          marginBottom: "0px",
                                                        }}
                                                      >
                                                        <span
                                                          style={{
                                                            display: "flex",
                                                            justifyContent:
                                                              "center",
                                                            alignItems:
                                                              "center",
                                                            marginRight: "1rem",
                                                          }}
                                                        >
                                                          {elem.daysLeftToReAuthorise >=
                                                          46 ? (
                                                            <GreenClockIconBank font-size="20px" />
                                                          ) : elem.daysLeftToReAuthorise <=
                                                              46 &&
                                                            elem.daysLeftToReAuthorise >=
                                                              16 ? (
                                                            <AmberClockIconBank font-size="20px" />
                                                          ) : (
                                                            <RedClockIconBank font-size="20px" />
                                                          )}
                                                        </span>
                                                        {
                                                          elem.daysLeftToReAuthorise
                                                        }{" "}
                                                        days
                                                      </p>
                                                    ) : (
                                                      <p
                                                        style={{
                                                          textDecoration:
                                                            "underline",
                                                          color: "#ffffff69",
                                                          cursor: "pointer",
                                                          flex: "1",
                                                          display: "flex",
                                                          justifyContent:
                                                            "center",
                                                          alignItems: "center",
                                                          marginTop: "0px",
                                                          marginBottom: "0px",
                                                        }}
                                                        onClick={() =>
                                                          reauthoriseAccount(
                                                            elem.id
                                                          )
                                                        }
                                                      >
                                                        Re-Authorise
                                                      </p>
                                                    )}
                                                    <p
                                                      style={{
                                                        textDecoration:
                                                          "underline",
                                                        color: "#ffffff69",
                                                        cursor: "pointer",
                                                        flex: "1",
                                                        display: "flex",
                                                        justifyContent:
                                                          "center",
                                                        alignItems: "center",
                                                        marginTop: "0px",
                                                        marginBottom: "0px",
                                                      }}
                                                      onClick={() =>
                                                        removeAccount(elem.id)
                                                      }
                                                    >
                                                      Remove
                                                    </p>
                                                  </li>
                                                );
                                              }
                                            )}
                                          </ul>
                                        </>
                                      )}
                                      <Col
                                        sm={3}
                                        style={{
                                          padding: "0px",
                                          marginLeft: "12px",
                                        }}
                                      >
                                        <BasicButton
                                          style={{
                                            paddingRight: "4px",
                                            paddingLeft: "4px",
                                            width: "fit-content",
                                            margin: "0px",
                                            marginTop: "10px",
                                          }}
                                          onClick={(e) => {
                                            e.preventDefault();
                                            const date = new Date();
                                            let todayDate = date.getDate();
                                            let endDate = new Date(
                                              date.getTime() +
                                                90 * 24 * 60 * 60 * 1000
                                            );
                                            // Format today's date in mm-dd-yyyy format
                                            const formattedTodayDate = `${(
                                              date.getMonth() + 1
                                            )
                                              .toString()
                                              .padStart(2, "0")}-${todayDate
                                              .toString()
                                              .padStart(
                                                2,
                                                "0"
                                              )}-${date.getFullYear()}`;
                                            // Format end date in mm-dd-yyyy format
                                            const formattedEndDate = `${(
                                              endDate.getMonth() + 1
                                            )
                                              .toString()
                                              .padStart(2, "0")}-${endDate
                                              .getDate()
                                              .toString()
                                              .padStart(
                                                2,
                                                "0"
                                              )}-${endDate.getFullYear()}`;
                                            setConsentStartDate(
                                              formattedTodayDate
                                            );
                                            setConsentEndDate(formattedEndDate);
                                            setShowPopover(!showPopover);
                                          }}
                                        >
                                          Add Bank Account
                                        </BasicButton>
                                      </Col>
                                    </Row>
                                  </Accordion.Body>
                                </Accordion.Item>
                              </Authorise>
                              {/* <Authorise
                                showUnAuthorisedComponenet={<></>}
                                allowedRoles={["admin"]}
                              >
                                <Accordion.Item eventKey="3">
                                  <Accordion.Header>
                                    Do you want to setup a payment gateway for
                                    receiving and sending payments?
                                  </Accordion.Header>
                                  <Accordion.Body>
                                    <>
                                      <Formik
                                        //initialValues={{}}
                                        // validationSchema={}
                                        onSubmit={(
                                          values,
                                          { setSubmitting }
                                        ) => {
                                          setSubmitting(true);
                                        }}
                                      >
                                        {({ handleSubmit }) => (
                                          <Form className="mx-auto">
                                            <Innersection>
                                              <Row>
                                                <Col>
                                                  <InputwithLabel
                                                    placeholder="Secret Key"
                                                    name="secretKey"
                                                    type="text"
                                                    label="Secret Key"
                                                  />

                                                  <InputwithLabel
                                                    placeholder="Client Key"
                                                    name="clientKey"
                                                    type="text"
                                                    label="Client Key"
                                                  />
                                                </Col>
                                              </Row>
                                            </Innersection>

                                            <BasicButton
                                              style={{ marginBottom: "30px" }}
                                              type="button"
                                              width="130px"
                                            >
                                              Verify
                                            </BasicButton>
                                          </Form>
                                        )}
                                      </Formik>
                                    </>
                                  </Accordion.Body>
                                </Accordion.Item>
                              </Authorise> */}
                            </StyledAccordion>
                          </OuterDiv>
                        </Card.Body>
                      </Card>
                    </StyledCard>

                    <NavigationButtons>
                      <BasicButton
                        className="back-button"
                        type="button"
                        width="110px"
                        onClick={() =>
                          history.push(Route_URLs.setup_connect_integration)
                        }
                      >
                        Back
                      </BasicButton>
                      <BasicButton
                        className="next-button"
                        type="submit"
                        width="110px"
                      >
                        Next
                      </BasicButton>
                    </NavigationButtons>
                  </Form>
                )}
              </Formik>
            </Page.Body>
          </Page>
        </PrivateLayout>
      )}
    </>
  );
}
export default GeneralSettings;